import { CDN_URL } from '../../config';
import { CDN_IMAGES } from '../../constants';

export type MascotEmotions = 'calm' | 'disappointed' | 'sad' | 'happy' | 'laughting' | 'welcome';
export function mascotEmotions(value: MascotEmotions): string {
  return `${CDN_URL}/assets/common/images/emotions-mascot/${value}.png`;
}

export function paymentMethodLogo(value: 'paypal'): string {
  return `${CDN_URL}/assets/accounts/images/payment-methods-logo/${value}.png`;
}

export function tftFreeLittleLegendPlanCard(): string {
  return `${CDN_IMAGES}/tft-free-egg/hushtail-plan-card.png`;
}

export function tftFreeLittleLegendPlanDetails(): string {
  return `${CDN_IMAGES}/tft-free-egg/hushtail-plan-details.png`;
}

export function lolFreeSkin(): string {
  return `${CDN_IMAGES}/lol-free-skin/skin.png`;
}
