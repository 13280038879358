import { AppPreloadedState } from './types/app.types';

const getEnvVar = (key: keyof AppPreloadedState, fallback: string): string => {
  return window.__PRELOADED_STATE__ ? window.__PRELOADED_STATE__[key] || fallback : fallback;
};

// general
export const FALLBACK_REDIRECT_URL = getEnvVar('FALLBACK_REDIRECT_URL', 'https://app.mobalytics.gg');

// api
export const DESKTOP_REDIRECT_OPEN_URL = getEnvVar('DESKTOP_REDIRECT_URL', 'mobalytics://open');
export const GQL_HTTP_URL = getEnvVar('GQL_HTTP_URL', 'https://account.stg.mobalytics.gg/api/graphql/v1/query');
export const TREASURY_HTTP_URL = getEnvVar(
  'TREASURY_HTTP_URL',
  'https://stg.mobalytics.gg/api/treasury/v1/graphql/query'
);

// payments
export const STRIPE_TOKEN = getEnvVar('STRIPE_TOKEN', 'pk_test_lFMd3c4Aw0MrSpPrpJTSAbE3');
export const BRAIN_TREE_TOKEN = getEnvVar('BRAIN_TREE_TOKEN', 'sandbox_w3zx2j3r_wc53kqdp3gbpxz84');
export const PAYPAL_ENV = getEnvVar('PAYPAL_ENV', 'sandbox');
export const PAYPAL_CLIENT_ID = getEnvVar(
  'PAYPAL_CLIENT_ID',
  'ATS3quWs760w07tcmd7macEtczYXQYqRZeicDI1-hM834NHWQGmDGgeutanZo7TWrJoKyn7wVRYaiZ8k'
);

// marketing
export const MIXPANEL_TOKEN = getEnvVar('MIXPANEL_TOKEN', '58655fdd54d84aa0d0ad3ccbbae326e7');

// cdn
export const CDN_URL = getEnvVar('CDN_URL', 'https://cdn.mobalytics.gg');

export const HOTJAR_CONFIG = {
  hjid: 3486546,
  hjsv: 6,
};
